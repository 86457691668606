.table_border_top {
    border: 1px solid rgba(231, 234, 243, 0.70);
    border-radius: 20px;
    background-color: var(--white);
}


.table_border {
    border-bottom: 1px solid rgba(231, 234, 243, 0.70);
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding-bottom: 10px; */
    padding: 10px 35px;
}

.table_filter {
    display: flex;
    gap: 10px;
}

.table_filter_name {
    padding-top: 5px;
}

.table_user_details {
    width: 50%;
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
}

.table_user_title {
    background-color: var(--white);
    border-left: 5px solid var(--white);
    border-bottom: 1px solid #eef0f6;

}

.table_user_left_border {
    border-left: 5px solid var(--primary) !important;
}

.table_user_title:hover {
    background-color: #f4f7ff;
    border-left: 5px solid #f4f7ff;
}

.table_user_title::before {
    background-color: var(--primary);
}

.table_user_title_border {
    display: flex;
    justify-content: space-between;
    padding: 15px 30px;
}



.modal_popup {
    display: flex;
    padding: 5px;
    /* gap: 20px; */
    padding-top: 10px;
}

.modal_popup_title {
    padding-top: 5px;
    width: 200px;
    /* text-align: center; */
}

.modal_popup_input {
    height: 35;
}

.table_btn_div .table_btn_edit {
    display: none;
    font-family: var(--primary-font-family) !important;
    text-transform: none !important;
    border-radius: 20px !important;
    height: 30px !important;

}

.modal_popup_draft_btn {
    display: flex;
    padding: 20px;
    gap: 10px;
    justify-content: flex-end;
}

.modal_popup_draft_btn button {
    text-transform: none !important;
    font-size: 14px;
    font-family: var(--primary-font-family);
}

.table_user_title:hover .table_btn_edit {
    display: inline-flex;
}

.table_user_more_btn {
    border: 1px solid rgba(231, 234, 243, 0.70);
    border-radius: 50%;
    fill: var(--primary);
}

.table_user_more_btn:hover {
    /* border: 1px solid rgba(231, 234, 243, 0.70);
    border-radius: 50%; */
    background-color: var(--primary);
    fill: var(--white);
}

.table_user_delete_btn {
    border: 1px solid rgba(231, 234, 243, 0.70);
    border-radius: 50%;
    fill: var(--danger) !important;
    cursor: pointer !important;
}

.table_user_delete_btn:hover {
    background-color: rgb(255 55 55 / 8%) !important;
    fill: var(--danger) !important;
    cursor: pointer !important;
}

.table_btn_div {
    display: flex;
    gap: 20px;
    padding-top: 5px;
    justify-content: start;
    align-items: center;
}

.table_edit_popover {
    position: relative;
    /* mt: 10px; */

}

.table_edit_popove::before {
    background-color: var(--white);
    content: '';
    display: block;
    position: absolute;
    width: 12;
    height: 12;
    top: -6;
    transform: rotate(45deg);
    left: calc(50% - 6px);
}

.table_moreinfo_popper {
    /* border: 1px solid rgba(231, 234, 243, 0.70); */
    display: flex;
    flex-direction: column;
    /* border-radius: 10px; */
    /* box-shadow: var(--box-shadow); */
    padding: 10px;
}

.table_moreinfo_popper button {
    justify-content: start !important;
    text-transform: none !important;
    color: black;
}

.table_body_data {
    height: 65vh;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.table_trash_btns {
    display: flex;
    gap: 20px;
}

.table_trash_btns button {
    text-transform: none !important;
    font-size: 14px;
    font-family: var(--primary-font-family);
    border-radius: 10px !important;
}

.table_trash_btns .table_trash_btns_delete {
    color: var(--danger) !important;
}

.table_moreinfo_popper .table_trash_btns_delete {
    color: var(--danger) !important;
}

.table_trash_btns .table_trash_btns_delete:hover {
    background-color: rgb(255 55 55 / 8%) !important;
}

.table_moreinfo_popper .table_trash_btns_delete:hover {
    background-color: rgb(255 55 55 / 8%) !important;
}

.table_Select_box span {
    font-size: 14px;
    font-family: var(--primary-font-family);
}

.table_moreinfo_popper button {
    font-size: 14px;
    font-family: var(--primary-font-family);
}

.table_title_name {
    font-size: 14px;
    font-family: var(--primary-font-family);
    font-weight: 500;
    width: 450px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.table_title_phara {
    font-family: var(--primary-font-family);
    font-weight: 400;
    font-size: 12px;
    padding-top: 0.5rem;
}

.table_body_error_head {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
}

.table_body_error_text {
    font-family: var(--primary-font-family);
    color: var(--black);
}

.table_body_error {
    display: flex;
    justify-content: center;
}

.date_picker_wrap {
    width: 100%;
    display: flex;
}

.date_picker {
    width: 100% !important;
    height: 41.92px !important;
    border: 1px solid var(--border-color) !important;
    border-radius: 4px !important;
    outline: unset;
    padding-left: 10px;
    font-size: 14px !important;
    font-family: var(--primary-font-family);
    position: relative;
    width: 327px;
}

.date_picker:hover {
    border-color: var(--primary) !important;
}

.date_pickerr {
    width: 100%;
}

.clear_icon_wrap {
    position: absolute;
    right: 0px !important;
    margin: 10px !important;
}

.clear_icon_wrap svg {
    color: rgba(0, 0, 0, 0.54) !important;
}

.link_edit {
    color: white;
    text-decoration: unset;
}

.table_title_name span {
    /* width: 650px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; */
}
.tooltip_table span {
    width: 350px;
    color: red;
    text-overflow: ellipsis;
    max-width: 350px;
    white-space: nowrap;
    overflow: hidden;
}


@media (max-width: 600px) {
    .table_body_data {
        height: 45vh;
        /* Adjust height for smaller screens */
    }
}

@media (max-height: 700px) {
    .table_body_data {
        height: 55vh;
    }
}