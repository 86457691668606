@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

* {
    box-sizing: border-box;
    font-family: 'Inter';
}

body {
    margin: 0;
    padding: 0;
    font-family: var(--primary-font-family);
    overflow: hidden;
}

.main_wrap {
    min-height: 100vh;
    display: flex;
    width: 100%;
    background-color: #F9FAFC;
    font-family: 'Inter';
}

.main_content {
    width: 100%;
    height: 100%;
}

:root {
    --primary: #377DFF;
    --secondary: #132144;
    --white: #ffffff;
    --black: #101316;
    --success: #23ab94;
    --danger: #ed4c78;
    --border-color: rgba(231, 234, 243, 0.70);
    --box-shadow: 0 0.6125rem 2.5rem 0.6125rem rgba(140, 152, 164, .175);
    --box-shadow-hover: 0 0.1875rem 0.75rem #8c98a440;
    --table-head-bg: #fafafd;
    --primary-font-family: Inter;
    --font-color: #132144;
    --table-font-color: #1E2022;
    --table-header-font: #677788;
    --header-color: #171a1fff;
}

.close_icons {
    display: flex !important;
    align-items: center !important;
}

.Toastify__toast--success {
    border: 1px solid #77EC95;
    color: #008A2E !important;
    background: #D7F5DD !important;
    box-shadow: 0 0 10px 1px #ddddddc7 !important;
    border-radius: 8px !important;
    font-size: 14px !important;
}

.Toastify__toast--warning {
    border: 1px solid #fdf8e9;
    color: #c59505 !important;
    background: #fdf8e9 !important;
    box-shadow: 0 0 10px 1px #ddddddc7 !important;
    border-radius: 8px !important;
    font-size: 14px !important;
    font-family: var(--primary-font-family) !important;
    line-height: 20px;
}

.Toastify__toast--error {
    border: 1px solid #FF9192;
    color: #A10E25 !important;
    background: #FFE9E8 !important;
    box-shadow: 0 0 10px 1px #ddddddc7 !important;
    font-size: 14px !important;
}

.css-1xfcrj6-MuiTableCell-root {
    font-family: Inter !important;
}

.main_wrapper {
    width: 100% !important;
    overflow: hidden !important;
    position: relative !important;
}

.welcome_page_select {
    margin-top: 18px !important;
    top: 108px !important;
    translate: -5px !important;
    min-width: 180px !important;
    box-shadow: 0 10px 40px 10px rgba(140, 152, 164, .18) !important;
}

.fc-timegrid-axis-cushion {
    color: var(--table-header-font);
    font-size: 14px;
}

.fc-daygrid-event:hover {
    background-color: transparent !important;
}

.fc-list-empty {
    background-color: rgba(231, 234, 243, .7) !important;
}

.fc .fc-list-empty-cushion {
    font-size: 14px !important;
    color: var(--table-header-font) !important;
}

.fc .fc-list-empty {
    height: 21% !important;
}

.fc-timegrid-event .fc-event-main {
    padding: 0px !important;
}

.fc-daygrid-event {
    overflow: hidden !important;
}

.fc-daygrid-more-link {
    color: var(--primary) !important;
    font-size: 12px !important;
    cursor: pointer !important;
    margin: 5px 0px 0px 5px !important;
}

.fc-daygrid-more-link:hover {
    background-color: transparent !important;
}

.fc-theme-standard .fc-popover-header {
    border-top-left-radius: .75rem !important;
    border-top-right-radius: .75rem !important;
    background-color: #f8fafd !important;
    padding: .5rem .75rem !important;
}

.fc-icon {
    width: 1em !important;
    height: 1em !important;
    -webkit-user-select: none !important;
    user-select: none !important;
    font-style: normal !important;
    font-variant: normal !important;
    line-height: 1 !important;
    -webkit-font-smoothing: antialiased !important;
    color: var(--table-header-font) !important;
}

.fc .fc-popover-title {
    color: var(--table-header-font) !important;
    font-size: 14px !important;
}

.fc-theme-standard .fc-popover {
    border-radius: .75rem !important;
}

.fc-theme-standard .fc-popover {
    height: auto !important;
}

.fc .fc-timegrid-slot-minor {
    border-top-style: none !important;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
    padding: 8px !important;
}

.css-r8u8y9 {
    padding: 8px !important;
}