.main_div {
    height: calc(100% - 70px);
}

.topbar_btn {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    padding: 10px;
    font-family: var(--primary-font-family) !important;
    color: var(--table-header-font);
}

.topbar_btn button {
    text-transform: none !important;
}

.topbar_back_btn {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    padding: 0px 60px 0px 60px;
    font-family: var(--primary-font-family) !important;
    color: var(--table-header-font);
}

.topbar_back_btnn button {
    text-transform: none !important;
}

.table_user_more_btn {
    border: 1px solid rgba(231, 234, 243, 0.70);
    border-radius: 50%;
    fill: var(--primary);
}

.table_user_more_btn:hover {
    background-color: var(--primary);
    fill: var(--white);
}

.table_moreinfo_popper {
    /* border: 1px solid rgba(231, 234, 243, 0.70); */
    display: flex;
    flex-direction: column;
    /* border-radius: 10px; */
    /* box-shadow: var(--box-shadow); */
    padding: 10px;
}

.table_moreinfo_popper button {
    justify-content: start !important;
    text-transform: none !important;
    color: black;
}

.table_user_more_icon {
    display: flex;
    justify-content: center;
    align-items: center;
}

.file_wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 620px;
    background-color: #fff;
    /* border: 1px solid var(--border-color); */
    border-radius: 6px;
    box-shadow: 24;
    /* padding: 40px;  */
}

.file_head_name {
    background-color: #3899ec;
    min-height: 60px;
    padding: 18px;
    padding-left: 30px;
    font-weight: 400;
    font-size: 20px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    border-radius: 8px 8px 0 0;
    line-height: 24px;
    font-weight: bold;
}

.schedule_date_time {
    padding: 36px 30px 12px;
}

.settings_container {
    display: flex;
    align-items: center;
}

.setting_icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.post_setting {
    left: 0;
    font-family: var(--primary-font-family);
    color: var(--table-font-color);
    font-weight: 600;
    margin-left: 10px;
}

.featured_img_text {
    font-size: 14px;
    color: var(--table-font-color);
    display: flex;
    align-items: center;
    margin-top: 5px;
}

.upload_wrap {
    margin-top: 10px;
    background-color: rgba(55, 125, 255, 0.04);
    border-radius: 3px;
    padding: 10px;
}

.upload_btn {
    padding: 30px !important;
    width: 100%;
    border: 1.5px dotted var(--primary) !important;
    background-color: transparent !important;
}

.info {
    color: var(--primary);
    border: 1.5px solid var(--primary);
    border-radius: 50%;
    font-size: 11px;
    font-weight: 600;
    width: 15px;
    height: 15px;
    margin-left: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.excerpt_field {
    font-size: 14px;
    color: var(--table-font-color);
    font-family: var(--primary-font-family);
    margin-top: 10px;
    border: 1px solid var(--border-color);
    outline: none;
    width: 100%;
    height: 50px;
    border-radius: 3px;
    resize: none;
}

.excerpt_field:hover {
    border: 1px solid var(--primary);
}

textarea::placeholder {
    font-size: 13px;
    color: var(--table-header-font);
    padding: 2px 0px 0px 2px;
}